<template>
  <div>
    <hero-bar>
      {{ $getTranslation('general.views.profile') }}
      <router-link slot="right" to="/" class="button">
        {{ $getTranslation('general.views.dashboard') }}
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component class="tile is-child">
          <div v-if="user">
            <div class="buttons">
              <b-button tag="router-link"
                  :to="{path: 'reset-password'}"
                  type="is-primary"
                  icon-left="lock">
                  {{ $getTranslation('auth.reset.title') }}
              </b-button>
              <b-button tag="router-link" class="capitalize"
                  :to="{path: 'users/' + slug + '/edit'}"
                  :type="buttonType"
                  icon-left="account">
                  {{ editButtonText }}
              </b-button>
            </div>

            <hr>

            <form ref="form" @submit.prevent="handleSubmit">

              <b-field horizontal :label="$getTranslation('form.name.label')">
                <b-input v-model="form.name" type="text" disabled>{{ form.name }}</b-input>
              </b-field>

              <b-field horizontal :label="$getTranslation('form.username.label')">
                <b-input v-model="form.slug" type="text">{{ form.slug }}</b-input>
              </b-field>

              <b-field horizontal :label="$getTranslation('form.email.label')">
                <b-input v-model="form.email" type="text" disabled>{{ form.email }}</b-input>
              </b-field>

              <hr>

              <div v-if="isLoggedUser.roles.includes('admin') || isLoggedUser.roles.includes('owner')">
                <h3 class="subtitle is-size-3" @click="toggle()" v-if="user !== null && (isLoggedUser.roles.includes('admin') || isLoggedUser.roles.includes('owner'))">
                  {{ $getTranslation('general.views.role', 1, true) }} / {{ $getTranslation('general.views.permission', 1, true) }}
                  <b-icon icon="plus" type="is-primary"></b-icon>
                </h3>
                <div class="columns is-desktop">
                  <div v-if="!isHidden">
                    <div class="column" v-if="form.allRoles !== null">
                      <b-field :label="$getTranslation('general.views.role', 1)" class="field has-check" horizontal>
                        <checkbox-picker :options="getCheckboxObject(form.allRoles)" v-model="form.roles" :table="false" type="is-primary" disabled />
                      </b-field>
                    </div>

                    <div class="column" v-if="form.allPermissions !== null">
                      <b-field :label="$getTranslation('general.views.permission', 1)" class="has-check" horizontal>
                        <!-- add disabled if rights for editing are not given -->
                        <checkbox-picker :options="getCheckboxObjectDouble(form.allPermissions)" v-model="form.permissions" :table="false" type="is-primary" disabled />
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <!-- TODO: add field to just show exact roles/premissions -->
              </div>


              <div v-if="profile" class="my-5">
                <h3 class="subtitle is-size-3" @click="showProfile = !showProfile">
                  <!-- <b-icon icon="plus" type="is-primary"></b-icon> -->
                  {{ $getTranslation('general.views.profile') }}
                </h3>
                <div>
                  <div class="columns is-desktop" v-if="profile">

                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.general-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.gender.label')">
                        <b-input :value="form.profile.age" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.age.label')">
                        <b-input :value="form.profile.age" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.no-siblings.label')">
                        <b-input :value="form.profile.no_siblings" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.nationality')">
                        <b-input :value="form.profile.nationality" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.no-family.label')">
                        <b-input :value="$getTranslation('profile.no-family.results')[form.profile.no_family]" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div>


                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.family-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.relationship.label')">
                        <b-input :value="form.profile.relationship" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field v-if="showFilter.show_ind_partn" :label="$getTranslation('profile.partner-job.label')">
                        <b-input :value="$getTranslation('profile.partner-job.results')[form.profile.partner_job_status]" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.no-children.label')">
                        <b-input :value="form.profile.no_children" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div>

                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.education-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.education.label')">
                        <b-input :value="$getTranslation('profile.education.results')[form.profile.education]" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.completed-training.label')">
                        <b-input :value="form.profile.completed_training" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.qualifications.label')">
                        <b-input :value="form.profile.qualifications" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div>

                  </div>
                  <hr>
                  <div class="columns"  v-if="showFilter.show_post_job_start">
                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.job-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.job-start')">
                        <b-input :value="form.profile.job_start" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employment.label')">
                        <b-input :value="form.profile.employment" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employers.label')">
                        <b-input :value="form.profile.employers" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employment-years.label')">
                        <b-input :value="form.profile.employment_years" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employment-sector.label')">
                        <b-input :value="form.profile.employment_sector" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employees-range.label')">
                        <b-input :value="form.profile.employees_no_range" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.company-range.label')">
                        <b-input :value="form.profile.company_stars" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.profit-organization.label')">
                        <b-input :value="form.profile.profit_oriented" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employment-status.label')">
                        <b-input :value="form.profile.employment_status" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.company-existence.label')">
                        <b-input :value="form.profile.company_start" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.company-website.label')">
                        <b-input :value="form.profile.company_website" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div>

                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.current-job-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.job-title.label')">
                        <b-input :value="form.profile.job_title" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.contract-working-hours.label')">
                        <b-input :value="form.profile.contract_working_hours" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.actual-working-hours.label')">
                        <b-input :value="form.profile.actual_working_hours" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.working-days.label')">
                        <b-input :value="form.profile.working_days" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.home-working-days.label')">
                        <b-input :value="form.profile.home_working_days" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.employment-country.label')">
                        <b-input :value="form.profile.employment_country" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-experience.label')">
                        <b-input :value="form.profile.job_experience" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-client-contacts.label')">
                        <b-input :value="form.profile.job_client_contacts" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-salary.label')">
                        <b-input :value="form.profile.job_salary" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-responsibility.label')">
                        <b-input :value="form.profile.job_responsibility" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-responsibility-no.label')">
                        <b-input :value="form.profile.job_responsibility_number" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>

                    </div>

                    <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.job-environment-fields') }}</h3>
                      <b-field :label="$getTranslation('profile.job-team.label')">
                        <b-input :value="form.profile.job_team" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.team-no.label')" v-if="showFilter.show_team_items">
                        <b-input :value="form.profile.job_team_no" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.team-interaction.label')" v-if="showFilter.show_team_items">
                        <b-input :value="form.profile.job_team_interaction" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.team-sync.label')" v-if="showFilter.show_team_items">
                        <b-input :value="form.profile.job_team_sync" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.team-virtual.label')" v-if="showFilter.show_team_items">
                        <b-input :value="form.profile.job_team_virtual" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.team-spatial.label')" v-if="showFilter.show_team_items">
                        <b-input :value="form.profile.job_team_spatial" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-leader.label')">
                        <b-input :value="form.profile.job_leader" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.leader-gender.label')">
                        <b-input :value="form.profile.job_leader_gender" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.leader-job-years.label')">
                        <b-input :value="form.profile.job_leader_years" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.leader-interaction.label')">
                        <b-input :value="form.profile.job_leader_interaction" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.job-covid.label')">
                        <b-input :value="form.profile.job_covid" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div>
                  </div>
                </div><!-- end show profile -->
                <hr />
              </div>
            </form>
          </div>

        </card-component>
      </tiles>
    </section>
                <!-- <b-field label="Salutation" class="has-check">
                    <radio-picker :options="{one:$getTranslation('profile.mr'),two:$getTranslation('profile.mrs'), 'three': $getTranslation('profile.miss'),'four':$getTranslation('profile.company')}" v-model="form.profile.salutation"></radio-picker>
                  </b-field> -->
                  <!-- <div class="column">
                      <b-field :label="$getTranslation('profile.company')">
                        <b-input :value="form.profile.company" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.fname')">
                        <b-input :value="form.profile.firstname" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.lname')">
                        <b-input :value="form.profile.lastname" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div> -->
                  <!-- <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.address') }}</h3>
                      <b-field :label="$getTranslation('profile.home1')">
                        <b-input :value="form.profile.address_line_1" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.home2')">
                        <b-input :value="form.profile.address_line_2" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.city')">
                        <b-input :value="form.profile.city" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.state')">
                        <b-input :value="form.profile.state" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.country')">
                        <b-input :value="form.profile.country" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div> -->
                    <!-- <div class="column">
                      <h3 class="subtitle">{{ $getTranslation('profile.contacts') }}</h3>
                      <b-field :label="$getTranslation('profile.phone')">
                        <b-input :value="form.profile.phone" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.mobile')">
                        <b-input :value="form.profile.mobile" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.fax')">
                        <b-input :value="form.profile.fax" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.email')">
                        <b-input :value="form.profile.email" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                      <b-field :label="$getTranslation('profile.web')">
                        <b-input :value="form.profile.web" :custom-class="type" :readonly="isReadonly"/>
                      </b-field>
                    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ApiUsers } from '@/api-routes.js'
import Tiles from '@/components/Tiles'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-profile',
  mixins: [FormTemplateMixin],
  components: {
    Tiles
  },
  data () {
    return {
      // allow inline editing
      isEditable: false,
      type: 'is-static',
      buttonType: 'is-primary',
      profile: true,
      // showProfile: false,
      slug: this.isLoggedUser ? this.isLoggedUser.slug : null,
      formSession: {
        name: null,
        email: null,
        slug: this.isLoggedUser ? this.isLoggedUser.slug : null,
        allRoles: null,
        allPermissions: null,
        userRole: null,
        // Profile
        profile: {
          salutation: null,
          company: null,
          firstname: null,
          lastname: null,
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: null,
          country: 'DE',
          phone: null,
          mobile: null,
          fax: null,
          email: null,
          web: null,
          description: null,
          interests: null,
          //------
          job_status: null,
          job_start: null,
          gender: null,
          birthdate: null,
          birthyear: null,
          nationality: null,
          no_siblings: null,
          relationship: null,
          partner_job_status: null,
          no_children: null,
          no_family: null,
          completed_training: null,
          //------
          age:null,
          education:null,
          employment_company_no:null,
          first_employment:null,
          employment_years:null,
          qualifications:null,
          employment_sector:null,
          other_employment_sector:null,
          employees_no_range:null,
          company_stars:null,
          company_website:null,
          job_title:null,
          contract_working_hours:null,
          actual_working_hours:null,
          working_days:null,
          home_working_days:null,
          employment_country:null,
          job_experience:null,
          job_client_contacts:null,
          job_salary:null,
          job_responsibility:null,
          job_responsibility_number:null,
          job_team:null,
          job_team_no:null,
          job_team_interaction:null,
          job_team_sync:null,
          job_team_virtual:null,
          job_team_spatial:null,
          job_leader:null,
          job_leader_gender:null,
          job_leader_years:null,
          job_leader_interaction:null,
          job_covid:null,
          profit_oriented:null,
          employment_status:null,
          company_start:null,
        }
      },
      type: 'is-static',
      buttonType: 'is-primary',
      editButtonText: this.$getTranslation('form.general.edit') + ' ' + this.$getTranslation('general.views.profile')
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),

    showFilter () {
      return {
        show_ind_partn: this.form.profile.relationship !== "S" && this.form.profile.relationship !== "W",
        show_post_job_start: this.job_start_2plus_months_ago(this.form.profile.job_start),
        show_un_bran_add: this.form.profile.employment_sector === "8" || this.form.profile.employment_sector === 8,
        show_un_ret_add: this.form.profile.employment_status === "1" || this.form.profile.employment_status === 1,
        show_jo_resp_add: this.form.profile.job_responsibility === "1" || this.form.profile.job_responsibility === 1,
        show_team_items: this.form.profile.job_team === "1" || this.form.profile.job_team === 1,
      }
    },

    form: {
      get: function () {
        let form = {};

        // form is empty if user is created, has props from formSession
        form = this.formSession

        // On create user, slug is not defined, form is empty
        if (typeof this.slug === "undefined" || this.slug === null) {
          // this.formSession = form
          return form
        }

        // Assign data from backend to the form, and save values in formSession
        // for reset action
        this.setFormValues (form)
        // TODO: only admins and owner might see roles and permissions
        /*if(this.isLoggedUser.roles !== 'admin' || this.isLoggedUser.roles !== 'owner') {
          form.allRoles = null
          form.allPermissions = null
        }*/
        return form
      },
      // setter needed to reset the form
      set: function(form) {
        this.setFormValues (form)
      }
    }
  },

  methods: {
    ...mapActions([
      "signUserUp",
      "saveItem",
      "fetchUser",
    ]),

    job_start_2plus_months_ago(job_start) {
      // I am so sorry for the shitty method name but it's late and I'm not very creative
      // This will calculate the number of days between now and job_start and return true
      // if that value is more than 60 (which is a good enough approximation of two months).
      // the magic number 86400000 is the number of milliseconds in a day.
      const dt = new Date(job_start)
      const today = new Date()
      return Math.round(Math.abs((today - dt) / 86400000)) > 60
    },

    submit () {
      if(typeof(this.slug) !== 'undefined' && this.slug.length) {
        let payload = {
          endpoint: this.endpoint + '/' + this.slug,
          method: this.formMethod,
          data: this.fetchData()
        }

        this.saveItem(payload)
          .then(response => {
            // console.log("form submit: ", baseUrl);
            // clean objects
            this.formSession =  {}
            // location.reload();
            this.redirectTo()
          })
          .catch(error => {
            console.log("ERROR form submit: ", error);
          });
      } else {
        this.signUserUp(this.form)
      }
    },

    reset () {
      this.form = this.formSession
    },

    setFormValues (form) {
      // console.log('set form values: ', this.user)
      if(this.user !== null) {
        form.name = this.user.name
        form.slug = this.user.slug
        this.email = form.email = this.user.email
        if (this.user.allRoles !== null) {
          form.allRoles = this.user.allRoles
        }
        if (this.user.allPermissions !== null) {
          form.allPermissions = this.user.allPermissions
        }

        if (this.user.roles !== null) {
          form.roles = this.buildArray(this.user.roles)
        }
        if (this.user.permissions !== null) {
          form.permissions = this.buildArray(this.user.permissions)
        }

        if(this.user.profile !== null) {
          this.profile = true
          let profile = this.user.profile
          // let profile = this.user.profile
          // Profile
          form.profile = {
            salutation: profile.salutation,
            company: profile.company,
            firstname: profile.firstname,
            lastname: profile.lastname,
            address_line_1: profile.address_line_1,
            address_line_2: profile.address_line_2,
            city: profile.city,
            state: profile.state,
            country: profile.country,
            phone: profile.phone,
            mobile: profile.mobile,
            fax: profile.fax,
            email: profile.email,
            web: profile.web,
            description: profile.description,
            interests: profile.interests,
            job_status: profile.job_status !== null ? this.$getTranslation('profile.job-status.results.' + profile.job_status): '-',
            job_start: profile.job_start !== null ? profile.job_start : '-',
            gender: profile.gender !== null ? this.$getTranslation('profile.gender.results.' + profile.gender): '-',
            birthdate: profile.birthdate !== null ? profile.birthdate: '-',
            birthyear: profile.birthyear !== null ? profile.birthyear: '-',
            nationality: profile.nationality !== "U" ? profile.nationality: this.$getTranslation('profile.unknown'),
            no_siblings: profile.no_siblings !== null ? profile.no_siblings: '-',
            relationship: profile.relationship !== null ? this.$getTranslation('profile.relationship.results.' + profile.relationship): '-',
            partner_job_status: profile.partner_job_status !== null ? profile.partner_job_status : '-',
            no_children: profile.no_children !== null ? profile.no_children: '-',
            no_family: profile.no_family !== null ? profile.no_family: '-',
            age: profile.age,
            education: profile.education,
            completed_training: profile.completed_training !== "U" ? profile.completed_training : this.$getTranslation('profile.unknown'),
            employment_company_no: profile.employment_company_no,
            first_employment: profile.first_employment,
            employment_years: profile.employment_years,
            qualifications: profile.qualifications !== "U" ? profile.qualifications : this.$getTranslation('profile.unknown'),
            employment_sector: profile.employment_sector !== "U" ? profile.employment_sector : this.$getTranslation('profile.unknown'),
            other_employment_sector: profile.other_employment_sector !== "U" ? profile.other_employment_sector : this.$getTranslation('profile.unknown'),
            employees_no_range: profile.employees_no_range,
            company_stars: profile.company_stars,
            company_website:profile.company_website !== "U" ? profile.company_website : this.$getTranslation('profile.unknown'),
            job_title: profile.job_title !== "U" ? profile.job_title : this.$getTranslation('profile.unknown'),
            contract_working_hours: profile.contract_working_hours,
            actual_working_hours: profile.actual_working_hours,
            working_days:profile.working_days,
            home_working_days:profile.home_working_days,
            employment_country: profile.employment_country !== "U" ? profile.employment_country : this.$getTranslation('profile.unknown'),
            job_experience: profile.job_experience,
            job_client_contacts: profile.job_client_contacts,
            job_salary: profile.job_salary,
            job_responsibility: profile.job_responsibility,
            job_responsibility_number: profile.job_responsibility_number,
            job_team: profile.job_team,
            job_team_no: profile.job_team_no,
            job_team_interaction: profile.job_team_interaction,
            job_team_sync: profile.job_team_sync,
            job_team_virtual: profile.job_team_virtual,
            job_team_spatial: profile.job_team_spatial,
            job_leader: profile.job_leader,
            job_leader_gender: profile.job_leader_gender !== "U" ? profile.job_leader_gender : this.$getTranslation('profile.unknown'),
            job_leader_years: profile.job_leader_years,
            job_leader_interaction: profile.job_leader_interaction,
            job_covid: profile.job_covid,
            profit_oriented: profile.profit_oriented,
            employment_status: profile.employment_status,
            company_start: profile.company_start,
            completed_training: profile.completed_training
          }

        } else {
          // empty fields
          form.profile = {
            salutation: null,
            company: null,
            firstname: null,
            lastname: null,
            address_line_1: null,
            address_line_2: null,
            city: null,
            state: null,
            country: null,
            phone: null,
            mobile: null,
            fax: null,
            email: null,
            web: null,
            description: null,
            interests: null,
            job_status: null,
            job_start: null,
            gender: null,
            birthdate: null,
            birthyear: null,
            nationality: null,
            no_siblings: null,
            relationship: null,
            partner_job_status: null,
            no_children: null,
            no_family: null,
            age:null,
            education:null,
            completed_training:null,
            employment_company_no:null,
            first_employment:null,
            employment_years:null,
            qualifications:null,
            employment_sector:null,
            other_employment_sector:null,
            employees_no_range:null,
            company_stars:null,
            company_website:null,
            job_title:null,
            contract_working_hours:null,
            actual_working_hours:null,
            working_days:null,
            home_working_days:null,
            employment_country:null,
            job_experience:null,
            job_client_contacts:null,
            job_salary:null,
            job_responsibility:null,
            job_responsibility_number:null,
            job_team:null,
            job_team_no:null,
            job_team_interaction:null,
            job_team_sync:null,
            job_team_virtual:null,
            job_team_spatial:null,
            job_leader:null,
            job_leader_gender:null,
            job_leader_years:null,
            job_leader_interaction:null,
            job_covid:null,
            profit_oriented:null,
            employment_status:null,
            company_start:null,
            completed_training: null
          }
        }
        this.formSession = form
      }
    }
  },

  created () {
    this.moduleName = this.$route.meta.type

    // console.log('slug?', this.slug, this.isLoggedUser.slug)
    this.slug = this.isLoggedUser.slug
    if(typeof(this.slug) !== 'undefined' && this.slug.length) {
      this.title = this.$getTranslation('profile.edit')

      // console.log('slug: ', userSlug)
      // this.$store.dispatch('fetchUser', this.slug)
      this.fetchUser('profile')
        .then(response => {
          // console.log('res error returned', this.user, response)
        })
        .catch(error => {
          console.log('error returned', error)
        })

      this.formMethod = 'put'
    } else {
      // this.title = 'Create User'
      // console.log('title: ', this.title, ' formMethod', this.formMethod, this.moduleName, this.$route)
    }
  }
}
</script>
